import { createContext, useContext, useMemo } from "react";
const PdpContext = createContext({
    pdp: null,
    detail: null,
});
export const usePrismicPdp = () => {
    var _a;
    const previewPdp = (_a = useContext(PdpContext)) === null || _a === void 0 ? void 0 : _a.pdp;
    return useMemo(() => previewPdp, [previewPdp]);
};
export const useCtPdp = () => {
    var _a;
    const detail = (_a = useContext(PdpContext)) === null || _a === void 0 ? void 0 : _a.detail;
    return useMemo(() => detail, [detail]);
};
export const PdpProvider = ({ children, pdp, detail, }) => {
    return <PdpContext.Provider value={{ pdp, detail }}>{children}</PdpContext.Provider>;
};
