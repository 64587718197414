import { PageNotFound, getNotFoundProps } from "@/lib/notFound";
import { fetchPages, getPage } from "@/lib/ssg";
import { CategoryPageContent, ListerContent, ShopTheLookPageContent, buildCategoryPage } from "@/lib/ssg/buildCategory";
import { MarketingPageContent, buildMarketPage } from "@/lib/ssg/buildMarket";
import { ProductPageContent, buildProductPage } from "@/lib/ssg/buildProduct";
import { ServicePageContent, buildServicePage } from "@/lib/ssg/buildService";
import { NOT_FOUND, RedisCache, SSGCache, generateSitemap, getBaseCategoryFromPrettyPath, getPreBuildPageType, isAssetPath, lowerCaseLocale, } from "@jog/shared";
import { useRouter } from "next/router";
const fetchData = async () => {
    const cache = new SSGCache();
    if (process.env.NODE_ENV === "development") {
        if (!cache.exists()) {
            const { pages } = await fetchPages();
            await cache.save(pages);
        }
        return [];
    }
    else {
        const { pages, sitemapPages } = await fetchPages();
        const redis = new RedisCache();
        await redis.write(pages);
        generateSitemap(sitemapPages);
        await cache.save(pages);
        return pages;
    }
};
export async function getStaticPaths() {
    const preBuildPageType = getPreBuildPageType();
    const pages = await fetchData();
    const paths = pages
        .filter((page) => page.path && preBuildPageType.includes(page.type))
        .map(({ params }) => ({ params, locale: process.env.NEXT_PUBLIC_LANG }));
    return { paths, fallback: "blocking" };
}
export async function getStaticProps(context) {
    const pathname = context.params.slug.join("/");
    if (isAssetPath(pathname))
        return NOT_FOUND();
    const locale = lowerCaseLocale();
    const path = pathname.includes("_") ? getBaseCategoryFromPrettyPath(pathname).join("/") : pathname;
    const redisItem = await getPage(path, locale);
    if (!(redisItem === null || redisItem === void 0 ? void 0 : redisItem.type))
        return getNotFoundProps(context);
    try {
        switch (redisItem.type) {
            case "category": {
                return buildCategoryPage(redisItem, context);
            }
            case "service": {
                return buildServicePage(redisItem, context);
            }
            case "market": {
                return buildMarketPage(redisItem, context);
            }
            case "product": {
                return buildProductPage(redisItem, context);
            }
            default: {
                return getNotFoundProps(context);
            }
        }
    }
    catch (e) {
        console.error(e);
        return getNotFoundProps(context);
    }
}
export default function App({ page, featuredProducts }) {
    const { isFallback } = useRouter();
    if (isFallback || !page)
        return <></>;
    switch (page.type) {
        case "404": {
            return <PageNotFound />;
        }
        case "market":
            return <MarketingPageContent page={page} featuredProducts={featuredProducts}/>;
        case "service":
            return <ServicePageContent page={page}/>;
        case "category":
            return <CategoryPageContent page={page} featuredProducts={featuredProducts}/>;
        case "product":
            return <ProductPageContent page={page}/>;
        case "shopTheLook":
            return <ShopTheLookPageContent page={page}/>;
        case "lister":
            return <ListerContent page={page}/>;
        default:
            throw new Error("Unknown page context");
    }
}
