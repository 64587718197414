import { navigate, responsiveImages } from "@jog/shared";
import { useCallback, useMemo, useState } from "react";
export const useGuidedSelling = (code, getGuidedSelling) => {
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState(0);
    const [answersArr, setAnswersArr] = useState([]);
    const [currentSelected, setCurrentSelected] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [navigateLoading, setNavigateLoading] = useState(false);
    const [dropdownShow, setDropdownShow] = useState(false);
    const imageSizes = ["575", "767", "991", "1370"];
    const { getSizes } = responsiveImages();
    const sizes = getSizes(imageSizes);
    const getGuidedSellingWithCode = useCallback(async (answers) => {
        setLoading(true);
        return getGuidedSelling(code, answers)
            .then((res) => {
            if (!res.questions)
                return;
            window === null || window === void 0 ? void 0 : window.scrollTo({ top: 0 });
            setSteps(res === null || res === void 0 ? void 0 : res.questions.length);
            const index = res === null || res === void 0 ? void 0 : res.questions.findIndex((question) => question.current);
            if (index !== -1) {
                setStep(index);
                setCurrentQuestion(res.questions[index]);
            }
            else {
                setCurrentQuestion(null);
            }
            const isEnd = res === null || res === void 0 ? void 0 : res.questions.every((question) => !question.current);
            if (isEnd) {
                setNavigateLoading(true);
                navigate(`/search`, res.navigationurl);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [code, getGuidedSelling]);
    const onClickMultiple = useCallback((answer) => {
        const isExist = currentSelected.some((item) => item === `${answer.answerid}`);
        const newSelectedArr = isExist
            ? currentSelected.filter((item) => item !== `${answer.answerid}`)
            : [...currentSelected, `${answer.answerid}`];
        setCurrentSelected(newSelectedArr);
    }, [currentSelected]);
    const onClickSingle = useCallback((answer) => {
        dropdownShow && setDropdownShow(false);
        setCurrentSelected([`${answer.answerid}`]);
    }, [dropdownShow]);
    const nextStep = useCallback(async (answerArr) => {
        setAnswersArr(answerArr);
        await getGuidedSellingWithCode(answerArr);
        setCurrentSelected([]);
    }, [getGuidedSellingWithCode]);
    const previousStep = useCallback(() => {
        const lastOne = answersArr.pop() || ["", ""];
        setCurrentSelected(lastOne[1].split(","));
        setAnswersArr(answersArr);
        getGuidedSellingWithCode(answersArr);
    }, [answersArr, getGuidedSellingWithCode]);
    const currentSelectedText = useMemo(() => {
        const currentSelectArr = (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers.filter((answer) => currentSelected.includes(`${answer.answerid}`)).map((item) => item.answer)) || [];
        return currentSelectArr.join(", ");
    }, [currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers, currentSelected]);
    const isSizeQuestion = useMemo(() => currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers.some((answer) => /(^\d+\/\d+$)|(^\d+$)|(^X*[S|M|L]$)/i.test(answer.answer)), [currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.answers]);
    return {
        step,
        steps,
        answersArr,
        currentSelected,
        currentQuestion,
        loading,
        navigateLoading,
        isSizeQuestion,
        dropdownShow,
        currentSelectedText,
        sizes,
        setDropdownShow,
        onClickMultiple,
        onClickSingle,
        nextStep,
        previousStep,
    };
};
