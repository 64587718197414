import { JCRichText } from "@/components";
import { useTextComponent } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
export const TextComponent = ({ slice: { primary, items }, className }) => {
    const { column, textContainer } = useTextComponent(primary.columns, primary.text_align, "text-black");
    return (<div className={classNames("grid gap-4 py-4", column, className)}>
            {items
            .filter((i) => isFilled.richText(i.rich_text_line))
            .map((item, index) => (<div key={index} className={textContainer}>
                        <JCRichText field={item.rich_text_line}/>
                    </div>))}
        </div>);
};
