import { JCRichText } from "@/components/RichText/RichText";
import { NextImage } from "@jog/react-components";
import { asImageSrc, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const gridsMapForPC = {
    2: "lg:grid-cols-2",
    3: "lg:grid-cols-3",
    4: "lg:grid-cols-4",
    5: "lg:grid-cols-5",
};
const gridsMapForMobile = {
    1: "grid-cols-1",
    2: "grid-cols-2",
    3: "grid-cols-3",
};
export const InfoLabelsWithPic = ({ slice: { primary, items }, className }) => (<div className={classNames("pt-8", className)} style={{ marginTop: `${primary.slice_top_margin}px` }}>
        {isFilled.richText(primary.labels_title) && (<div className="mb-2.5 text-center" style={{ color: primary.title_color, marginBottom: `${primary.title_bottom_margin}px` }}>
                <JCRichText field={primary.labels_title}/>
            </div>)}
        <div className={classNames("grid gap-x-4 gap-y-2 lg:gap-x-8 lg:gap-y-4", gridsMapForPC[primary.cols_per_line_pc] || gridsMapForPC["3"], gridsMapForMobile[primary.cols_per_line_mobile] || gridsMapForMobile["1"])} style={{ color: primary.label_text_color }}>
            {items
        .filter((i) => isFilled.image(i.label_icon) && isFilled.richText(i.label_text))
        .map((item, index) => (<div key={index} className="flex flex-col items-center justify-center py-2.5 px-2.5 lg:px-5" style={{ backgroundColor: primary.label_bg_color }}>
                        <NextImage width={300} height={300} className="mb-5 w-auto min-w-[33%]" src={asImageSrc(item.label_icon)} alt={item.label_icon.alt}/>
                        <div className="text-center">
                            <JCRichText field={item.label_text}/>
                        </div>
                    </div>))}
        </div>
    </div>);
