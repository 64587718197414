import { JCRichText } from "@/components";
import { useCtPdp } from "@jog/react-components";
export const Sustainable = () => {
    const { sustainable } = useCtPdp();
    const headingCss = "border border-gray-anthracite py-1.25 px-2.5 font-normal uppercase font-primary mb-2 text-lg";
    return (<div>
            {sustainable.flat().map((item, index) => (<div className="bg-pastelOlive p-3.75 font-primary text-sm text-gray-anthracite" key={index}>
                    <JCRichText field={item} h1Class={headingCss} h2Class={headingCss} h3Class={headingCss} h4Class={headingCss} h5Class={headingCss} h6Class={headingCss} paragraphClass="text-sm !mb-0" linkClass="text-gray-anthracite underline"/>
                </div>))}
        </div>);
};
