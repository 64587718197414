import { replaceTextInRichText } from "@jog/shared";
import { useMemo } from "react";
import { useI18n } from "./useI18n";
const handleText = (formerText, latterText) => {
    const sizeNode = formerText === null || formerText === void 0 ? void 0 : formerText[0];
    const toolNode = latterText === null || latterText === void 0 ? void 0 : latterText[0];
    const spans = toolNode.spans.map((span) => {
        return {
            type: span.type,
            start: span.start + sizeNode.text.length,
            end: span.end + sizeNode.text.length,
        };
    });
    return [
        {
            ...toolNode,
            text: sizeNode.text + toolNode.text,
            spans: [...sizeNode.spans, ...spans],
        },
    ];
};
export const useSizeConflictText = (cartItems, ctpId, rawText, rawToolRecommendText, rawOwnSizeRecommendText, sku) => {
    const { $t } = useI18n();
    const currentSizesInCart = useMemo(() => (cartItems === null || cartItems === void 0 ? void 0 : cartItems.filter((lineItem) => (lineItem === null || lineItem === void 0 ? void 0 : lineItem.productId) === ctpId).map((item) => (item.length ? `${item.size}/${item.length}` : item.size))) || [], [cartItems, ctpId]);
    const currentSkuInCart = useMemo(() => (cartItems === null || cartItems === void 0 ? void 0 : cartItems.filter((lineItem) => (lineItem === null || lineItem === void 0 ? void 0 : lineItem.productId) === ctpId).map((item) => item.sku)) || [], [cartItems, ctpId]);
    const sizeConflictText = useMemo(() => {
        const replacedText = currentSizesInCart.length > 2
            ? [currentSizesInCart.slice(0, -1).join(", "), ...currentSizesInCart.slice(-1)].join(" and ")
            : currentSizesInCart.join(` ${$t("and")} `);
        return replaceTextInRichText(rawText, "{X}", replacedText);
    }, [$t, currentSizesInCart, rawText]);
    const toolRecommendText = useMemo(() => handleText(sizeConflictText, rawToolRecommendText), [rawToolRecommendText, sizeConflictText]);
    const ownSizeRecommendText = useMemo(() => handleText(sizeConflictText, rawOwnSizeRecommendText || rawToolRecommendText), [rawOwnSizeRecommendText, rawToolRecommendText, sizeConflictText]);
    const isShowSizeAlert = useMemo(() => sku && currentSkuInCart.length && !currentSkuInCart.includes(sku) && sizeConflictText, [currentSkuInCart, sizeConflictText, sku]);
    return {
        isShowSizeAlert,
        toolRecommendText,
        ownSizeRecommendText,
    };
};
