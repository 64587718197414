import { JCRichText } from "@/components";
import { useStores } from "@/stores";
import { Arrow, useI18n, usePrismicPdp } from "@jog/react-components";
import { useSocialProofText } from "@jog/react-components";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
export const AddToCart = observer(({ sku, onAdd }) => {
    const { cartStore } = useStores();
    const session = useSession();
    const { socialProofText, socialProofTextColor } = usePrismicPdp();
    const { socialProof, pageView, targetEl } = useSocialProofText(socialProofText);
    const { $t } = useI18n();
    const [noSize, setNoSize] = useState(false);
    useEffect(() => {
        sku && setNoSize(false);
    }, [sku]);
    const addProductToCart = async () => {
        if (!sku) {
            setNoSize(true);
            return;
        }
        if (cartStore.isCtaDisabled)
            return;
        await cartStore.addProductToCart(session.status === "authenticated", sku);
        onAdd();
    };
    return (<div className="mx-0 -mt-2.5 flex flex-col flex-wrap items-start md:mt-0" ref={targetEl}>
            {noSize ? (<span className="mb-1 h-5.5 text-left text-base text-red-main">{$t("Select your size")}</span>) : null}
            <button onClick={addProductToCart} className="group relative inline-block w-full bg-orange-main py-2.5 px-10 text-center font-primary text-base font-bold uppercase text-white opacity-100 hover:bg-green-main focus:outline-none md:w-auto">
                <Arrow className="absolute top-1/2 left-3.5 -translate-y-1/2 duration-300 group-hover:left-5.5" color="white" size="middle"/>
                <span>{$t("Add To Cart")}</span>
            </button>
            {/* the className sqzly-proof-contain is a mark which is the anchor of sqzly inserting content */}
            <span className="sqzly-proof-contain"/>
            {pageView ? (<div className="mt-3 lg:mt-6" style={{ color: socialProofTextColor }}>
                    <JCRichText field={socialProof}/>
                </div>) : null}
        </div>);
});
