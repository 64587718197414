import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { usePrevNextButtons } from "../../hooks";
import { Arrow } from "../Icon/Arrow";
import { Link } from "../Link/Link";
import { NextImage } from "../NextImage/NextImage";
const Item = ({ variant, currentId }) => (<div className="shrink-0 grow-0 basis-1/5 pl-3 lg:basis-[12.5%]" key={variant.itemno}>
        <Link to={variant.url} title={variant.color || variant.title} className={classNames("aspect-w-2 aspect-h-3 block border border-gray-base")} onClick={(event) => currentId === variant.itemno && event.preventDefault()}>
            <NextImage width="667" height="1000" className={classNames("border hover:border-gray-base", currentId === variant.itemno ? "border-gray-base" : "border-transparent")} quality={100} src={variant.imageUrl} alt={variant.color || variant.title || ""} title={variant.color}/>
        </Link>
    </div>);
export const GroupSelector = ({ variants, currentId, title, Button, }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "start",
        active: variants.length > 8,
        breakpoints: {
            "(max-width: 991px)": { active: variants.length > 5 },
        },
    });
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    if (variants.length <= 1) {
        return <></>;
    }
    return (<div>
            {title}
            <div className="relative">
                <div ref={emblaRef} className="w-full overflow-hidden py-3">
                    <div className="-ml-3 flex touch-pan-y">
                        {variants.map((variant) => (<Item key={variant.itemno} variant={variant} currentId={currentId}/>))}
                    </div>
                </div>
                <div>
                    <Button className="absolute -left-3.5 top-1/2 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow disabled:hidden" type="button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                        <Arrow height={16} width={16}/>
                    </Button>
                    <Button disabled={nextBtnDisabled} className="absolute -right-3.5 top-1/2 flex h-7 w-7 translate-x-0.5 -translate-y-1/2 rotate-180 items-center justify-center rounded-full bg-white shadow disabled:hidden" type="button" onClick={onNextButtonClick}>
                        <Arrow height={16} width={16}/>
                    </Button>
                </div>
            </div>
        </div>);
};
