import { JCRichText } from "@/components";
import { Link, NextImage } from "@jog/react-components";
import { asImageSrc, asLink, asText, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const gridOptions = {
    1: "grid-cols-1",
    2: "grid-cols-1 md:grid-cols-2",
    3: "grid-cols-1 md:grid-cols-3",
    4: "grid-cols-1 sm:grid-cols-2 lg:grid-cols-4",
};
const orderReverse = {
    1: "order-4",
    2: "order-3",
    3: "order-2",
    4: "order-1",
};
const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
};
export const ImageText = ({ slice: { primary, items }, className }) => (<div className={classNames("grid gap-2", gridOptions[primary.columns] || gridOptions["1"], className)}>
        {items.map((item, i) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return (<div className={classNames({
                [`${orderReverse[i + 1]} md:order-none`]: primary.mobile_top === "Right Column",
            })} key={i}>
                {item.display_image && !isFilled.link(item.link) && isFilled.image(item.image) ? (<NextImage className="w-full" alt={asText(item.title1)} src={asImageSrc(item.image)} width={(_b = (_a = item.image) === null || _a === void 0 ? void 0 : _a.dimensions) === null || _b === void 0 ? void 0 : _b.width} height={(_d = (_c = item.image) === null || _c === void 0 ? void 0 : _c.dimensions) === null || _d === void 0 ? void 0 : _d.height}/>) : null}

                {item.display_image && isFilled.link(item.link) && isFilled.image(item.image) ? (<Link to={asLink(item.link)} target={item.link.link_type === "Web" ? item.link.target : "_self"} rel="noopener noreferrer" external={true}>
                        <NextImage className="w-full" alt={asText(item.title1)} src={asImageSrc(item.image)} width={(_f = (_e = item.image) === null || _e === void 0 ? void 0 : _e.dimensions) === null || _f === void 0 ? void 0 : _f.width} height={(_h = (_g = item.image) === null || _g === void 0 ? void 0 : _g.dimensions) === null || _h === void 0 ? void 0 : _h.height}/>
                    </Link>) : null}
                <div className={classNames({ "flex h-full items-center justify-center": !item.display_image })}>
                    <div className={classNames("py-2.5 px-2 text-gray-base", item.display_image ? "md:px-0 md:py-0" : "md:px-24", textAlign[primary.text_align] || "text-center")}>
                        {isFilled.richText(item.title1) && <JCRichText field={item.title1}/>}
                        {isFilled.richText(item.rich_text) && <JCRichText field={item.rich_text}/>}
                    </div>
                </div>
            </div>);
    })}
    </div>);
