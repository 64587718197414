import { fetchPages as fetchPagesBase } from "@jog/jeanscentre-base";
import { RedisCache, SSGCache, getSoftPageUrls, lowerCaseLocale, transformCategorySoftPageSitemap, transformSitemapData, } from "@jog/shared";
export const fetchPages = async (callback = console.log) => {
    const locale = lowerCaseLocale();
    const { servicePages, categoryPages, productPages, marketPages } = await fetchPagesBase(locale, callback);
    const categorySoftPages = await getSoftPageUrls().then((res) => res.body.softPageUrls.flat());
    callback(`Fetch category soft pages done, total ${categorySoftPages.length}`);
    const sitemapPages = {
        category: transformSitemapData(categoryPages),
        product: transformSitemapData(productPages),
        marketing: transformSitemapData(marketPages),
        categorySoft: transformCategorySoftPageSitemap(categorySoftPages),
    };
    return {
        pages: [...servicePages, ...marketPages, ...categoryPages, ...productPages],
        sitemapPages,
    };
};
export const getPage = (path, locale) => {
    if (process.env.NODE_ENV === "production") {
        if (process.env.FILE_CACHE_STRATEGY === "fs") {
            const cache = new SSGCache();
            return cache.get(path, locale);
        }
        const redisCache = new RedisCache();
        return redisCache.fetch(path, locale);
    }
    else {
        const cache = new SSGCache();
        return cache.get(path, locale);
    }
};
