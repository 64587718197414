import { allMarketQuery, getPrismicPages, log, prismicGQLClient, RecursionGenPages, typedBoolean } from "@jog/shared";
export const getAllMarketPages = async (locale) => {
    log("market");
    const edges = await RecursionGenPages(prismicGQLClient(), {
        locale: locale.toLowerCase(),
        query: allMarketQuery
    });
    return edges
        .map((document) => getPrismicPages(locale, document))
        .filter(typedBoolean)
        .map((i) => ({ ...i, type: "market" }));
};
