import { Container, ErrorBoundary, Head } from "@/components";
import { useCtPdp } from "@jog/react-components";
import { usePrismicPdp } from "@jog/react-components";
import { useMemo } from "react";
export const Main = ({ productData, sku, children }) => {
    const { name, description, desktopImages, color, size, material, paths } = useCtPdp();
    const { metaTagRobots } = usePrismicPdp();
    const productStructuredData = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (!productData)
            return null;
        return {
            name,
            description,
            sku,
            images: desktopImages.map((val) => val === null || val === void 0 ? void 0 : val.url),
            color,
            brand: (_a = productData.brand) !== null && _a !== void 0 ? _a : "",
            price: (_c = ((_b = productData.price) === null || _b === void 0 ? void 0 : _b.centAmount) / 100) !== null && _c !== void 0 ? _c : 0,
            currency: (_e = (_d = productData.price) === null || _d === void 0 ? void 0 : _d.currencyCode) !== null && _e !== void 0 ? _e : "",
            size,
            material,
        };
    }, [productData, name, description, sku, desktopImages, color, size, material]);
    return (<ErrorBoundary system="PDP">
            <Head alternate={paths} title={`${name} | Jeans Centre`} description={description} robots={metaTagRobots} productStructuredData={productStructuredData}/>
            <Container>{children}</Container>
        </ErrorBoundary>);
};
