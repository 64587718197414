import { useCtPdp } from "@jog/react-components";
import { usePrismicPdp } from "@jog/react-components";
import { PromoInformationSlice, PromotionalImage } from "@jog/react-components";
import { PdpPrice } from "./PdpPrice";
import { RatingStar } from "./RatingStar";
const ReviewsSummary = ({ averageRating, count }) => {
    return (<div className="flex">
            <div className="my-0 ml-0 mr-2.5 flex items-center whitespace-nowrap md:mx-2.75">
                <RatingStar rank={averageRating}/>
            </div>
            <span className="mr-2.25">|</span>
            <div className="mr-2.25 cursor-pointer hover:text-orange-main" onClick={() => window.scrollTo({
            top: document.getElementById("reviews_container").offsetTop - 170,
            behavior: "smooth",
        })}>
                ({Math.round(count)})
            </div>
        </div>);
};
export const Header = ({ productData, promotionTag, reviews, }) => {
    var _a;
    const { promoInformation } = usePrismicPdp();
    const { name, slug } = useCtPdp();
    return (<div>
            <div className="relative">
                <h1 className="pr-15 text-22 uppercase text-gray-base lg:pr-20">{name}</h1>
                <div className="flex flex-col md:flex-row md:items-center">
                    <h6 className="uppercase text-gray-base md:mt-1">{slug}</h6>
                    {((_a = reviews === null || reviews === void 0 ? void 0 : reviews.items) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (<ReviewsSummary averageRating={reviews.averageRating} count={reviews.total}/>) : null}
                </div>
                {productData && (<div className="mt-3 flex flex-wrap items-center md:mt-4.75">
                        <PdpPrice productData={productData}/>
                    </div>)}

                <PromotionalImage className="absolute right-0 top-2.5 w-15 lg:top-0 lg:w-20" promotionTag={promotionTag}/>
            </div>

            {promoInformation.map((item, index) => (<div key={index}>
                    <PromoInformationSlice color={item.color} richTexts={item.richTexts}/>
                </div>))}
        </div>);
};
