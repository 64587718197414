import { BannerSlots, Buttons, CarouselProducts, FeaturedProducts, SingleBanner, TextHeadline } from "@/Slice";
import { EmbedContent } from "@/Slice/EmbedContent";
import { GuidedSelling } from "@/Slice/GuidedSelling";
import { ImageText } from "@/Slice/ImageText";
import { InfoLabelsWithPic } from "@/Slice/InfoLabelsWithPic";
import { TextComponent } from "@/Slice/TextComponent";
import { VideoBanner } from "@/Slice/VideoBanner";
import { Container, ErrorBoundary } from "@/components";
export const SliceRender = ({ slices }) => (<div>
        {[...slices]
        .filter((slice) => !slice.primary.is_hide_module)
        .map((slice, index) => (<Slice key={index} slice={slice}/>))}
    </div>);
const BASE_MARGIN = "mt-2 first:mt-0";
const SliceContainer = ({ SliceComponent, slice, isFullWidth, }) => {
    return (<Container className={BASE_MARGIN} isFullWidth={isFullWidth}>
            <ErrorBoundary system="CMS">
                <SliceComponent slice={slice}/>
            </ErrorBoundary>
        </Container>);
};
export const Slice = ({ slice }) => {
    switch (slice.slice_type) {
        case "video_banner": {
            return (<SliceContainer slice={slice} SliceComponent={VideoBanner} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "single_banner": {
            return (<SliceContainer slice={slice} SliceComponent={SingleBanner} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "text_headline": {
            return <SliceContainer slice={slice} SliceComponent={TextHeadline}/>;
        }
        case "text_component": {
            return <SliceContainer slice={slice} SliceComponent={TextComponent}/>;
        }
        case "two_slot_banner":
        case "three_slot_banner":
        case "four_slot_banner":
        case "six_slot_banner": {
            return (<SliceContainer slice={slice} SliceComponent={BannerSlots} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "embed_content": {
            return <SliceContainer slice={slice} SliceComponent={EmbedContent}/>;
        }
        case "image___text": {
            return <SliceContainer slice={slice} SliceComponent={ImageText}/>;
        }
        case "featured_products": {
            return (<SliceContainer slice={slice} SliceComponent={FeaturedProducts} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "carousel_products": {
            return (<SliceContainer slice={slice} SliceComponent={CarouselProducts} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "info_labels_with_pic": {
            return <SliceContainer slice={slice} SliceComponent={InfoLabelsWithPic}/>;
        }
        case "guided_selling": {
            return (<SliceContainer slice={slice} SliceComponent={GuidedSelling} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "buttons": {
            return <SliceContainer SliceComponent={Buttons} slice={slice}/>;
        }
        default: {
            return <></>;
        }
    }
};
