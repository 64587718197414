import { getReviews } from "@/apis/getReviews";
import { useCtPdp } from "@jog/react-components";
import { useEffect, useState } from "react";
export const useProductReviews = () => {
    const { slug } = useCtPdp();
    const [reviews, setReviews] = useState();
    const [reviewLoading, setReviewLoading] = useState(true);
    useEffect(() => {
        setReviewLoading(true);
        getReviews(slug)
            .then((res) => setReviews(res))
            .finally(() => setReviewLoading(false));
    }, [slug]);
    return { reviews, reviewLoading };
};
