import { typedBoolean } from "@jog/shared";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { kebabCase } from "lodash-es";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
const sortByQuality = ["500", "1000", "2000", "mini"];
export const getItemData = (item, coverType) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const name = item.title;
    const categories = (_c = (_b = (_a = item.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => val.name === "categoryAsUrl" && val.values)) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0];
    const slug = (_f = (_e = (_d = item.attributes) === null || _d === void 0 ? void 0 : _d.find((val) => val.name === "slug" && val.values)) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f[0];
    const imageUrl = ((_j = (((_h = (_g = item.attributes) === null || _g === void 0 ? void 0 : _g.find((attribute) => attribute.name === "gallery")) === null || _h === void 0 ? void 0 : _h.values) || [])
        .map((item) => {
        try {
            return JSON.parse(item);
        }
        catch (error) {
            return;
        }
    })
        .filter(typedBoolean)
        .filter((item) => item.label.includes(coverType))
        .map((i) => {
        const quality = sortByQuality.indexOf(i.label.split("-").at(-1) || "");
        return { ...i, quality };
    })
        .filter(({ quality }) => quality > -1)
        .sort((a, b) => a.quality - b.quality)
        .at(0)) === null || _j === void 0 ? void 0 : _j.url) || item.image;
    const color = (_m = (_l = (_k = item.attributes) === null || _k === void 0 ? void 0 : _k.find((val) => val.name === "color" && val.values)) === null || _l === void 0 ? void 0 : _l.values) === null || _m === void 0 ? void 0 : _m[0];
    return {
        imageUrl,
        url: `/${categories}/${kebabCase(name)}/${kebabCase(slug)}`,
        title: item.title,
        itemno: item.itemno,
        color,
    };
};
export const useProductDetail = (fetch) => {
    const [pending, setPending] = useState(true);
    const [productData, setProductData] = useState(null);
    const [variants, setVariants] = useState([]);
    const router = useRouter();
    const fetchPdpData = useCallback(async () => {
        setPending(true);
        const res = await fetch();
        if (!res) {
            router.push("/404");
            return;
        }
        setProductData(res.product);
        setVariants(res.variants);
        setPending(false);
    }, [fetch, router]);
    useEffect(() => {
        router.isReady && fetchPdpData().catch();
    }, [fetchPdpData, router.isReady]);
    return {
        pending,
        productData,
        variants,
        promotionalBanner: useMemo(() => { var _a, _b; return ((_b = (_a = productData === null || productData === void 0 ? void 0 : productData.promotionalBanner) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b[process.env.NEXT_PUBLIC_LOCALE]) || ""; }, [productData]),
        gtmPrice: useMemo(() => {
            var _a;
            const price = ((_a = ((productData === null || productData === void 0 ? void 0 : productData.firstPrice) || (productData === null || productData === void 0 ? void 0 : productData.price))) === null || _a === void 0 ? void 0 : _a.centAmount) || 0;
            return +(price / 100).toFixed(2);
        }, [productData]),
    };
};
export const usePdpPrice = ({ firstPrice, price, discountedPrice, lowestPrice }) => {
    const originPrice = firstPrice || price;
    if (discountedPrice && discountedPrice.centAmount < originPrice.centAmount) {
        const currentPrice = discountedPrice.centAmount;
        const _originPrice = originPrice.centAmount;
        return {
            currentPrice: currentPrice,
            originPrice: _originPrice,
            lowestPrice,
            off: Math.floor(((_originPrice - currentPrice) / _originPrice) * 100),
        };
    }
    const currentPrice = price.centAmount;
    const _originPrice = firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.centAmount;
    return {
        currentPrice,
        originPrice: _originPrice,
        lowestPrice,
        off: 0,
    };
};
export const usePdpImages = (length) => {
    return useEmblaCarousel({ axis: "y", align: "start", active: length > 5 }, [WheelGesturesPlugin()]);
};
export const genPdpImageStyle = (space, width, imagesLength, carouselPadding = true) => {
    const gen = (slideW) => {
        const slideH = (slideW / 2) * 3;
        const zoomH = slideH * 5 + space * 4 + (imagesLength > 5 ? 0.4 * slideH : 0);
        const zoomW = (zoomH / 3) * 2;
        return {
            ["slide-w"]: `${slideW}px`,
            ["slide-h"]: `${slideH}px`,
            ["slide-container-h"]: `${zoomH + space}px`,
            ["zoom-w"]: `${zoomW}px`,
            ["zoom-h"]: `${zoomH}px`,
            ["carousel-w"]: `${zoomW + slideW + space * (carouselPadding ? 3 : 1)}px`,
        };
    };
    const lg = gen(width.lg);
    const xl = gen(width.xl);
    const xxl = gen(width.xxl);
    return {
        ["--carousel-space"]: `${space}px`,
        ["--lg-slide-w"]: lg["slide-w"],
        ["--lg-slide-h"]: lg["slide-h"],
        ["--lg-slide-container-h"]: lg["slide-container-h"],
        ["--lg-zoom-w"]: lg["zoom-w"],
        ["--lg-zoom-h"]: lg["zoom-h"],
        ["--lg-carousel-w"]: lg["carousel-w"],
        ["--xl-slide-w"]: xl["slide-w"],
        ["--xl-slide-h"]: xl["slide-h"],
        ["--xl-slide-container-h"]: xl["slide-container-h"],
        ["--xl-zoom-w"]: xl["zoom-w"],
        ["--xl-zoom-h"]: xl["zoom-h"],
        ["--xl-carousel-w"]: xl["carousel-w"],
        ["--xxl-slide-w"]: xxl["slide-w"],
        ["--xxl-slide-h"]: xxl["slide-h"],
        ["--xxl-slide-container-h"]: xxl["slide-container-h"],
        ["--xxl-zoom-w"]: xxl["zoom-w"],
        ["--xxl-zoom-h"]: xxl["zoom-h"],
        ["--xxl-carousel-w"]: xxl["carousel-w"],
    };
};
