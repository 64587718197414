import { Container, ErrorBoundary, Head, LogoLoader } from "@/components";
import { Product } from "@/components/ShopTheLook/Product";
import { getPdp } from "@/lib/jog";
import { useStores } from "@/stores";
import { ContentLoader, NextImage, useI18n, useShopTheLook } from "@jog/react-components";
import { navigation, retry } from "@jog/shared";
import { DescriptionBox } from "@jog/theming";
import { asImageSrc, asText } from "@prismicio/helpers";
import { observer } from "mobx-react";
import { useCallback } from "react";
export const ShopTheLook = observer(({ keys, prismic, paths, }) => {
    var _a, _b, _c, _d;
    const { cartStore } = useStores();
    const { $t } = useI18n();
    const fetch = useCallback(async () => {
        const prefix = process.env.NEXT_PUBLIC_TW_ROOT_CATEGORY_ID.slice(0, 4);
        const products = await retry(() => navigation(`tn_cid=${prefix}${keys[1]}`).then((res) => res.body.items));
        const details = await Promise.all(products.map((i) => getPdp(i.itemno).then((res) => res.body)));
        return {
            products,
            sizes: details.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.sizes }), {}),
        };
    }, [keys]);
    const { pending, products, sizes } = useShopTheLook(fetch, keys);
    return (<ErrorBoundary system="ShopTheLook">
                <Head alternate={paths} title={asText(prismic.meta_tag_title)} description={asText(prismic.meta_tag_description)} robots={asText(prismic.meta_tag_robots)}/>
                <Container>
                    {cartStore.addProductFetching && <LogoLoader />}
                    <div className="bg-white p-5 lg:px-14 lg:py-10">
                        <div className="grid grid-cols-12 grid-rows-[auto_1fr] gap-x-4">
                            <div className="col-span-12 row-start-1 md:col-span-6 md:col-start-7">
                                <div>
                                    <h1 className="my-5 text-3.5xl font-bold uppercase">
                                        {asText(prismic.title) || $t("Shop the look")}
                                    </h1>
                                    <h6 className="text-17 uppercase">{asText(prismic.subtitle)}</h6>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:row-span-2">
                                <NextImage src={asImageSrc(prismic.main_image)} width={(_b = (_a = prismic.main_image) === null || _a === void 0 ? void 0 : _a.dimensions) === null || _b === void 0 ? void 0 : _b.width} height={(_d = (_c = prismic.main_image) === null || _c === void 0 ? void 0 : _c.dimensions) === null || _d === void 0 ? void 0 : _d.height} alt="Product image" className="img-fluid"/>
                            </div>
                            <div className="col-span-12 row-start-3 md:col-span-6 md:col-start-7 md:row-start-2">
                                <DescriptionBox>{asText(prismic.description)}</DescriptionBox>
                                <div>
                                    {!pending
            ? products.map((product, index) => (<Product key={index} product={product} sizes={sizes[product.itemno]}/>))
            : Array(2)
                .fill(1)
                .map((_, index) => (<ContentLoader key={`loader_${index}`} height="200px"/>))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </ErrorBoundary>);
});
