import { JCRichText } from "@/components";
import { RankBar } from "@/components/RankBar/RankBar";
import { FullStar } from "@/components/StarIcon/StarIcon";
import { useCtPdp } from "@jog/react-components";
import { usePrismicPdp } from "@jog/react-components";
import { NextImage, useI18n } from "@jog/react-components";
import { lowerCaseLocale } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
import { useState } from "react";
import { RatingStar } from "./RatingStar";
const ReviewsItem = ({ reviewItem }) => {
    return (<div className="border-b border-b-gray-main py-4">
            <div className="mb-4 flex w-full items-center justify-between lg:w-1/3">
                <div className="font-bold">
                    {new Intl.DateTimeFormat(lowerCaseLocale())
            .format(new Date(reviewItem.submittedAt))
            .replaceAll("-", ".")}
                </div>
                <RatingStar rank={reviewItem.rating}/>
            </div>
            <div>{reviewItem.comment}</div>
        </div>);
};
export const Reviews = ({ reviews }) => {
    var _a;
    const { $t } = useI18n();
    const { reviewInfo } = usePrismicPdp();
    const { name } = useCtPdp();
    const [fold, setFold] = useState(true);
    if (!((_a = reviews === null || reviews === void 0 ? void 0 : reviews.items) === null || _a === void 0 ? void 0 : _a.length)) {
        return <></>;
    }
    return (<div id="reviews_container" className="mb-4 bg-white p-5 lg:p-4 ">
            {isFilled.richText(reviewInfo) && (<div className="mb-5 lg:mb-15">
                    <JCRichText paragraphClass="!mb-0" field={reviewInfo}/>
                </div>)}
            <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-8">
                    <h2 className="text-22 uppercase leading-7">{name}</h2>
                    <div className="border-b border-b-gray-main py-4">
                        <span className="mr-2 font-bold">{`${reviews.averageRating}/5`}</span>
                        <span>{`(${reviews.total} reviews)`}</span>
                    </div>
                    <div>
                        {(fold ? reviews.items.slice(0, 3) : reviews.items).map((reviewItem) => (<ReviewsItem reviewItem={reviewItem} key={reviewItem.id}/>))}
                    </div>
                </div>
                <div className="col-span-12 pb-4 lg:col-span-3 lg:col-start-10 lg:pt-12.5">
                    <div className="mb-1.5 flex items-center justify-between">
                        <span className="font-bold">{reviews.averageRating}</span>
                        <div className="w-11">
                            <NextImage src="/images/trusted-shop-icon.svg" width={20} height={20} alt="trusted shop icon"/>
                        </div>
                    </div>
                    <div>
                        {Object.keys(reviews.statistic)
            .reverse()
            .map((key) => (<RankBar key={key} score={key} StartIcon={() => <FullStar width={14} height={14} className="text-green-main"/>} percentage={`${+((reviews.statistic[key] * 100) / reviews.total).toFixed()}%`}/>))}
                    </div>
                </div>
            </div>
            {fold && reviews.items.length > 3 ? (<div className="mt-5 flex justify-center lg:mt-15">
                    <div className="cursor-pointer bg-green-main px-6 py-2.5 font-bold text-white" onClick={() => setFold(false)}>
                        {$t("load more")}
                    </div>
                </div>) : null}
        </div>);
};
