import { AttributeBoxLoader, useCtPdp } from "@jog/react-components";
import classNames from "classnames";
export const Attributes = () => {
    const { mappedAttributes: attributes } = useCtPdp();
    if (!attributes.length)
        return <AttributeBoxLoader />;
    return (<div className="bg-gray-medium p-3.75 text-sm text-gray-base">
            {attributes.map((attribute) => (<div className="grid grid-cols-2 px-2" key={attribute.name}>
                    <strong>{attribute.name}</strong>
                    <span className={classNames({ capitalize: attribute.capitalize })}>{attribute.value}</span>
                </div>))}
        </div>);
};
