import { replaceTextInRichText } from "@jog/shared";
import { useEffect, useMemo, useRef, useState } from "react";
const DEFAULT_OPTIONS = {
    config: { attributes: true, childList: true, subtree: true },
};
export const useSocialProofText = (proofText, options = DEFAULT_OPTIONS) => {
    const targetEl = useRef(null);
    const [pageView, setPageView] = useState("");
    const socialProof = useMemo(() => {
        return replaceTextInRichText(proofText, "{pageview_amount}", pageView);
    }, [proofText, pageView]);
    const [observer, setObserver] = useState(null);
    useEffect(() => {
        setObserver(new MutationObserver(() => {
            var _a;
            const text = ((_a = document.getElementById("sqzly-page-view-amount")) === null || _a === void 0 ? void 0 : _a.getAttribute("data-view")) || "";
            setPageView(text);
        }));
    }, []);
    useEffect(() => {
        if (!observer)
            return;
        const { config } = options;
        targetEl.current && observer.observe(targetEl.current, config);
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [observer, targetEl, options]);
    return {
        socialProof,
        pageView,
        targetEl,
    };
};
