import { allServiceQuery, getPrismicPages, log, prismicGQLClient, RecursionGenPages, typedBoolean } from "@jog/shared";
export const getAllServicePages = (locale) => {
    log("service");
    return RecursionGenPages(prismicGQLClient(), {
        locale: locale.toLowerCase(),
        query: allServiceQuery,
    }).then((edges) => edges
        .map((document) => getPrismicPages(locale, document))
        .filter(typedBoolean)
        .map((i) => ({ ...i, type: "service" })));
};
