import { fetchFeaturedProducts, typedBoolean } from "@jog/shared";
import { asLink, isFilled } from "@prismicio/helpers";
export const getFeaturedProducts = async (prismic, getPage) => {
    var _a, _b;
    if (!((_a = prismic.data.body) === null || _a === void 0 ? void 0 : _a.length))
        return [];
    const tempKeys = (_b = [...prismic.data.body]) === null || _b === void 0 ? void 0 : _b.filter((item) => ["featured_products", "carousel_products"].includes(item.slice_type)).map((slice) => {
        switch (slice.slice_type) {
            case "featured_products":
            case "carousel_products":
                return slice.items.map((link) => isFilled.link(link.product_link)
                    ? new URL(asLink(link.product_link)).pathname.replace(/^\//, "")
                    : "");
            default:
                return "";
        }
    }).filter(typedBoolean).flat();
    return fetchFeaturedProducts(tempKeys, getPage);
};
