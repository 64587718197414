import { allCategorysQuery, allListersQuery, allShopTheLooksQuery, categoriesApiQuery, categoryApiQuery, categoryTotal, getSSGPathData, graphqlClient, log, prismicGQLClient, RecursionGenPages, retry, typedBoolean } from "@jog/shared";
import { kebabCase } from "lodash-es";
let i = 0;
const fetchCategories = async (variables) => {
    return graphqlClient
        .execute({
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: categoriesApiQuery,
            variables
        }
    })
        .then((res) => {
        console.log(`${i++} Fetch category api ${variables.offset} ${variables.limit} finish`);
        return res.body.data.categories.results;
    });
};
export const getCtpCategoryPages = async () => {
    const request = {
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: categoryTotal
        }
    };
    log("categoryTotal");
    const totalNumber = await retry(() => graphqlClient.execute(request).then((res) => res.body.data.categories.total));
    const perPage = 200;
    const totalPages = Math.ceil(totalNumber / perPage);
    console.info(`Total categories: ${totalNumber}`);
    console.info(`Total pages: ${totalPages}`);
    const vars = [];
    for (let page = 0; page < totalPages; page++) {
        vars.push({ offset: page * perPage, limit: perPage });
    }
    log("fetchCategory");
    const data = await Promise.all(vars.map((i) => retry(() => fetchCategories(i))));
    return data.reduce((pre, cur) => [...pre, ...cur], []);
};
export const getCategoryPages = async (locale) => {
    log("categoryPages");
    const [listerPages, stlPages, prismicCategoryPages, ctpCategoryPages] = await Promise.all([
        RecursionGenPages(prismicGQLClient(), {
            locale,
            query: allListersQuery
        }),
        RecursionGenPages(prismicGQLClient(), {
            locale,
            query: allShopTheLooksQuery
        }),
        RecursionGenPages(prismicGQLClient(), { locale, query: allCategorysQuery }),
        getCtpCategoryPages()
    ]);
    const categoryParents = new CategoryParents(ctpCategoryPages, locale);
    const categoriesPathsArray = ctpCategoryPages
        .map((category) => {
        const parents = categoryParents.getParentsDescription(category.id);
        const pathParamsArr = parents.map((val) => kebabCase(val.description));
        return {
            parents,
            pathParamsArr,
            category
        };
    })
        .filter((i) => i.pathParamsArr.every(typedBoolean))
        .map(({ parents, pathParamsArr, category }) => {
        const keysArray = parents.map((val) => val.key);
        const categoryPath = `/${pathParamsArr.filter(typedBoolean).join("/")}`;
        return {
            parents,
            category,
            pathParamsArrays: {},
            pathParamsArr,
            keysArray,
            categoryPath
        };
    });
    const prismicMap = [listerPages, stlPages, prismicCategoryPages].flat().reduce((pre, cur) => ({
        ...pre,
        [`${cur._meta.uid}_${cur._meta.lang}`]: {
            id: cur._meta.id, type: cur._meta.type
        }
    }), {});
    return {
        categoriesPathsArray,
        categoryPages: categoriesPathsArray.map((i) => {
            const prismic = prismicMap[`${i.category.externalId.trim()}_${locale.toLowerCase()}`];
            return {
                lastModifiedAt: i.category.lastModifiedAt,
                deps: {
                    ct: {
                        ids: i.parents.map((i) => i.id),
                        id: i.category.id
                    },
                    prismic
                },
                extra: {
                    path: i.categoryPath,
                    keys: i.keysArray
                },
                type: "category",
                ...getSSGPathData(i.pathParamsArr, locale),
            };
        })
    };
};
class CategoryParents {
    constructor(categorys, locale) {
        this.categorys = categorys;
        this.locale = locale;
        this.descriptions = {};
        this.related = {};
        this.keys = {};
        this.init();
    }
    init() {
        this.categorys.forEach((i) => {
            var _a, _b;
            if ((_a = i === null || i === void 0 ? void 0 : i.parent) === null || _a === void 0 ? void 0 : _a.id) {
                Object.assign(this.related, { [i.id]: i.parent.id });
            }
            Object.assign(this.keys, { [i.id]: i.key });
            Object.assign(this.descriptions, {
                [i.id]: ((_b = i.descriptionAllLocales.find((val) => val.locale.toLowerCase() === this.locale)) === null || _b === void 0 ? void 0 : _b.value) || ""
            });
        });
    }
    find(id) {
        const parent = this.related[id];
        return parent ? [parent, ...this.find(parent)] : [];
    }
    getParentsDescription(id) {
        const arr = this.find(id)
            .map((k) => ({
            id: k,
            key: this.keys[k],
            description: this.descriptions[k]
        }))
            .filter(typedBoolean);
        arr.pop();
        arr.unshift({ id, description: this.descriptions[id], key: this.keys[id] });
        return arr.reverse();
    }
}
export const fetchCategory = async (variables) => {
    return graphqlClient
        .execute({
        uri: `/ctp/graphql`,
        method: "POST",
        body: { query: categoryApiQuery, variables }
    })
        .then((res) => res.body.data.category);
};
