import { getPdp } from "@/lib/jog";
import { getItemData, useCtPdp, usePrismicPdp, useProductDetail as useProductDetailBase } from "@jog/react-components";
import { getGroup, retry } from "@jog/shared";
import { useCallback } from "react";
export const useProductDetail = () => {
    const { ctpId } = useCtPdp();
    const prismicPdp = usePrismicPdp();
    const fetch = useCallback(async () => {
        var _a, _b, _c, _d;
        const data = await getPdp(ctpId).then((res) => res === null || res === void 0 ? void 0 : res.body);
        if (!data)
            return null;
        const groupId = (_b = (_a = data === null || data === void 0 ? void 0 : data.attributes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === "parent")) === null || _b === void 0 ? void 0 : _b.value;
        const groupData = groupId && groupId !== "-" ? await retry(() => getGroup(groupId)) : null;
        return {
            product: data,
            variants: ((_d = (_c = groupData === null || groupData === void 0 ? void 0 : groupData.body) === null || _c === void 0 ? void 0 : _c[groupId]) === null || _d === void 0 ? void 0 : _d.map((item) => getItemData(item, prismicPdp.variantItemCover))) || [],
        };
    }, [prismicPdp, ctpId]);
    return useProductDetailBase(fetch);
};
