export const RankBar = ({ StartIcon, score, percentage }) => {
    return (<div className="mb-1.5 flex items-center gap-2 text-sm">
            <div className="flex items-center gap-0.5 leading-none">
                <span className="mr-1">{score}</span>
                <StartIcon />
            </div>
            <div className="flex h-2 flex-1 rounded-full bg-gray-bg">
                <div className="inline-block h-full w-2 rounded bg-green-main" style={{ width: percentage }}></div>
            </div>
            <div className="w-11">{percentage}</div>
        </div>);
};
