import { ContentLoader, useCtPdp, usePdpImages, usePrevNextButtons } from "@jog/react-components";
import { NextImage, useI18n, useSwipeable } from "@jog/react-components";
import { Arrow } from "@jog/react-components/src/components/Icon/Arrow";
import classNames from "classnames";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
export const Carousel = ({ favorite }) => {
    var _a, _b, _c, _d, _e;
    const { brand, desktopImages, mobileImages } = useCtPdp();
    const { $t } = useI18n();
    const desktop = useSwipeable(desktopImages.length);
    const mobile = useSwipeable(mobileImages.length);
    const brandImage = brand ? (<div className="absolute top-2.5 left-5 z-[2] w-20 sm:w-30">
            <NextImage className="h-auto w-full" height={brand.height} width={brand.width} src={brand.url} alt={brand.alt}/>
        </div>) : (<ContentLoader height="25px" width="120px" className="absolute top-2.5 left-5 z-[2]"/>);
    const [ref, emblaApi] = usePdpImages(desktopImages.length);
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    if (!desktopImages.length) {
        return <div>{$t("There are no images for this product")}</div>;
    }
    return (<>
            <div className="relative hidden h-full lg:block lg:p-[var(--carousel-space)]">
                <div className="sticky top-[var(--carousel-space)]">
                    <div className={classNames("relative grid justify-between gap-x-[var(--carousel-space)]", "lg:grid-cols-[var(--lg-slide-w),var(--lg-zoom-w)] lg:grid-rows-[var(--lg-zoom-h)]", "xl:grid-cols-[var(--xl-slide-w),var(--xl-zoom-w)] xl:grid-rows-[var(--xl-zoom-h)]", "2xl:grid-cols-[var(--xxl-slide-w),var(--xxl-zoom-w)] 2xl:grid-rows-[var(--xxl-zoom-h)]")}>
                        <div className="relative">
                            <div ref={ref} className="overflow-hidden">
                                <div className="-mt-[var(--carousel-space)] flex touch-pan-x flex-col lg:h-[var(--lg-slide-container-h)] xl:h-[var(--xl-slide-container-h)] 2xl:h-[var(--xxl-slide-container-h)]">
                                    {desktopImages.map((image, i) => (<div key={i} className="min-h-0 shrink-0 grow-0 pt-[var(--carousel-space)] ">
                                            <div className="aspect-w-2 aspect-h-3" onClick={() => desktop.setActive(i)}>
                                                <NextImage src={image.lowest} alt="Thumbnail image" width={60} height={90} className={classNames("cursor-pointer border hover:border-black lg:h-[var(--lg-slide-h)] xl:h-[var(--xl-slide-h)] 2xl:h-[var(--xxl-slide-h)]", "h-full w-full object-contain", i === desktop.active ? "border-gray-base" : "border-gray-silver")}/>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div>
                                <button className="absolute -top-3.5 left-1/2 flex h-7 w-7 -translate-x-1/2 rotate-90 items-center justify-center rounded-full bg-white shadow hover:text-green-dark disabled:hidden" type="button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                                    <Arrow height={16} width={16}/>
                                </button>
                                <button disabled={nextBtnDisabled} className="absolute -bottom-3.5 left-1/2 flex h-7 w-7 -translate-x-1/2 -rotate-90 items-center justify-center rounded-full bg-white shadow hover:text-green-dark disabled:hidden" type="button" onClick={onNextButtonClick}>
                                    <Arrow height={16} width={16}/>
                                </button>
                            </div>
                        </div>
                        <div className="relative z-[1] h-auto touch-pan-y">
                            {brandImage}
                            <div className="flex">
                                <InnerImageZoom src={(_a = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _a === void 0 ? void 0 : _a.url} imgAttributes={{
            alt: ((_b = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _b === void 0 ? void 0 : _b.label) || "Product Image",
            className: classNames("lg:!h-[var(--lg-zoom-h)] lg:!w-[var(--lg-zoom-w)]", "xl:!h-[var(--xl-zoom-h)] xl:!w-[var(--xl-zoom-w)]", "2xl:!h-[var(--xxl-zoom-h)] 2xl:!w-[var(--xxl-zoom-w)]", "object-contain"),
        }} hideHint zoomSrc={(_c = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _c === void 0 ? void 0 : _c.highest}/>
                            </div>
                            {favorite}
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative block h-full lg:hidden">
                <div className="relative z-[1] grid h-auto touch-pan-y">
                    {brandImage}
                    <div className="flex">
                        <InnerImageZoom className="flex-1" src={(_d = mobileImages === null || mobileImages === void 0 ? void 0 : mobileImages[mobile.active]) === null || _d === void 0 ? void 0 : _d.url} zoomSrc={(_e = mobileImages === null || mobileImages === void 0 ? void 0 : mobileImages[mobile.active]) === null || _e === void 0 ? void 0 : _e.highest} imgAttributes={{ alt: "Product Image", className: "w-full max-h-[150vw]" }} hideHint/>
                    </div>
                    {favorite}
                    <div onClick={mobile.onSwipedRight} className="absolute left-0 top-1/2 -translate-y-1/2">
                        <NextImage blur={false} src="/images/right.png" alt="Previous" width="35" height="69"/>
                    </div>
                    <div onClick={mobile.onSwipedLeft} className="absolute right-0 top-1/2 -translate-y-1/2">
                        <NextImage blur={false} src="/images/left.png" alt="Next" width="35" height="69"/>
                    </div>
                </div>
                <div className="flex items-center justify-center gap-2.5 py-4">
                    {mobileImages.map((_, i) => (<button key={i} className={classNames("h-5 w-5 cursor-pointer rounded-full border", i === mobile.active ? "border-gray-base bg-gray-base" : "border-gray-silver bg-white")} onClick={() => mobile.setActive(i)}>
                            <span className="hidden">{`Carousel ${i}+1`}</span>
                        </button>))}
                </div>
            </div>
        </>);
};
