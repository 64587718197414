import { FullStar } from "@/components/StarIcon/StarIcon";
import { StarSkeletonIcon } from "@/components/StarSkeletonIcon/StarSkeletonIcon";
const Star = ({ score }) => {
    if (score >= 100) {
        return (<span className="block h-4 w-4">
                <FullStar width={16} height={16} className="text-green-main"/>
            </span>);
    }
    if (score <= 0) {
        return (<span className="block h-4 w-4">
                <StarSkeletonIcon width={16} height={16} className="text-green-main"/>
            </span>);
    }
    return (<span className="relative block h-4 w-4">
            <span className="absolute left-0 right-0 top-0 z-[1]">
                <StarSkeletonIcon width={16} height={16} className="text-green-main"/>
            </span>
            <span className="absolute left-0 top-0 z-[2] overflow-hidden" style={{ width: score + "%" }}>
                <FullStar width={16} height={16} className="text-green-main"/>
            </span>
        </span>);
};
export const RatingStar = ({ rank }) => {
    const int = Math.floor(rank);
    const stars = Array.from({ length: 5 })
        .fill(0)
        .map((_, i) => {
        if (i === int) {
            return Math.round(rank * 100 - int * 100);
        }
        if (i + 1 <= int)
            return 100;
        return 0;
    });
    return (<div className="mr-px flex space-x-px">
            {stars.map((score, i) => (<Star score={score} key={i}/>))}
        </div>);
};
