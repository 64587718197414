import { Modal } from "@/components";
import { NextImage, useCtPdp, useI18n } from "@jog/react-components";
import { gtmSizeChart } from "@jog/shared";
import { useState } from "react";
export const SizeModalButton = () => {
    const { sizeImage } = useCtPdp();
    const { $t } = useI18n();
    const [show, setShow] = useState(false);
    const toggleSizeModal = () => {
        setShow((v) => !v);
        gtmSizeChart();
    };
    return (<>
            <div className="flex cursor-pointer items-center" onClick={toggleSizeModal}>
                <NextImage blur={false} src="/images/maattabel-icon.png" alt="Maattabel Icon" width={20} height={20}/>
                <span className="m-1.25 block font-sans text-sm underline hover:no-underline">
                    {$t("Measure Modal Description")}
                </span>
            </div>
            {show && (<Modal hideModal={toggleSizeModal} headerTitle={$t("Measure Modal Description")}>
                    <NextImage className="img-fluid" src={sizeImage.image} width={sizeImage.width} height={sizeImage.height} alt="Product sizes information"/>
                </Modal>)}
        </>);
};
