import { FasletWidget as FasletWidgetFC } from "@faslet/react-widget";
import classNames from "classnames";
import { useSession } from "next-auth/react";
import { useCallback, useEffect, useMemo, useState, } from "react";
import { ClientOnly } from "../../ClientOnly";
import { useI18n } from "../../hooks";
export const FasletWidget = ({ pdpData, productName, productImage, productId, cartStore, setSku, fasletAvailable, setFasletAvailable, Icon, className, gtmId, onAdd, }) => {
    // Get the product variants and colors
    const { $t } = useI18n();
    const [btnLabel, setBtnLabel] = useState("");
    const shopId = process.env.NEXT_PUBLIC_FASLET_SHOP_ID;
    const variants = useMemo(() => {
        var _a;
        return (((_a = pdpData === null || pdpData === void 0 ? void 0 : pdpData.sizes) === null || _a === void 0 ? void 0 : _a.map((waistItem) => {
            return waistItem.lengthSizes.map((lengthItem) => {
                return {
                    variantId: lengthItem.sku,
                    sizeLabel: lengthItem.lengthSize
                        ? `${waistItem.waistSize}/${lengthItem.lengthSize}`
                        : `${waistItem.waistSize}`,
                    inStock: lengthItem.isOnStock,
                    sku: lengthItem.sku,
                    colorId: ``,
                    price: +(pdpData.price.centAmount / Math.pow(10, pdpData.price.fractionDigits)).toFixed(2),
                };
            });
        }).flat()) || []);
    }, [pdpData]);
    const isLoggedIn = useSession().status === "authenticated";
    const addProductToCart = useCallback(async (id) => {
        const product = variants.find((variant) => variant.variantId === id);
        const productSku = (product === null || product === void 0 ? void 0 : product.sku) || "";
        setSku(productSku);
        await cartStore.addProductToCart(isLoggedIn, productSku);
        onAdd();
    }, [cartStore, isLoggedIn, onAdd, setSku, variants]);
    // const [btnShow, setBtnShow] = useState(false)
    useEffect(() => {
        window._faslet = {
            onButtonShow: () => setFasletAvailable(true),
            onButtonHidden: () => setFasletAvailable(false),
        };
    }, [setFasletAvailable]);
    return (<ClientOnly>
            {shopId && gtmId && productId === (pdpData === null || pdpData === void 0 ? void 0 : pdpData.id) ? (<div id="faslet-button" className="faslet-container">
                    <FasletWidgetFC productId={gtmId} shopId={shopId} brandId={(pdpData === null || pdpData === void 0 ? void 0 : pdpData.brand) || ""} productImageUrl={productImage} productName={productName} variants={variants} onAddToCart={addProductToCart} onResult={({ label }) => setBtnLabel(label)}/>
                    {/* custom button text */}
                    {fasletAvailable ? (<span className={classNames("mt-4 inline-block cursor-pointer gap-1 leading-4", className)} id="faslet-button-text" onClick={() => {
                    var _a, _b;
                    (_b = (_a = window === null || window === void 0 ? void 0 : window._faslet) === null || _a === void 0 ? void 0 : _a.openWidget) === null || _b === void 0 ? void 0 : _b.call(_a);
                }}>
                            {btnLabel ? (<>
                                    <span>{$t("Your size is")}</span>
                                    <span className="ml-2 rounded border border-gray-silver px-2 pt-1.5 pb-1">
                                        {btnLabel}
                                    </span>
                                </>) : (<span className="flex items-center">
                                    {Icon}
                                    <span className="ml-1 underline">{$t("Measure indicator")}</span>
                                </span>)}
                        </span>) : null}
                </div>) : null}
        </ClientOnly>);
};
