import { JCRichText } from "@/components";
import { Accordion } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
export const Accordions = ({ className, slice: { primary, items } }) => (<div className={classNames(className, "py-10")}>
        {isFilled.richText(primary.accordions_title) ? (<div className="mt-3">
                <JCRichText field={primary.accordions_title}/>
            </div>) : null}
        <div className="mt-3 flex flex-col gap-1.75">
            {items.map((item, index) => (<Accordion key={index} textColor={primary.accordion_text_color || ""} bgColor={primary.accordion_bg_color || ""} title={<JCRichText paragraphClass="mt-2.5" field={item.accordion_title}/>} content={<JCRichText paragraphClass="mt-2.5" field={item.accordion_content}/>}/>))}
        </div>
    </div>);
