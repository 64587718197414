import { Buttons } from "@/Slice";
import { Accordions } from "@/Slice/Accordions";
import { CookieDeclaration } from "@/Slice/CookieDeclaration";
import { CtaButtons } from "@/Slice/CtaButtons";
import { InfoLabels } from "@/Slice/InfoLabels";
import { ServicePageContent } from "@/Slice/ServicePageContent";
export const SliceRender = ({ slices }) => (<div className="w-full overflow-auto bg-white py-8.75 px-6.25 text-15">
        {[...slices]
        .filter((slice) => !slice.primary.is_hide_module)
        .map((slice, index) => (<Slice key={index} slice={slice}/>))}
    </div>);
const BASE_MARGIN = "";
export const Slice = ({ slice }) => {
    switch (slice.slice_type) {
        case "service_page_content": {
            return <ServicePageContent className={BASE_MARGIN} slice={slice}/>;
        }
        case "info_labels": {
            return <InfoLabels className={BASE_MARGIN} slice={slice}/>;
        }
        case "cta_buttons": {
            return <CtaButtons className={BASE_MARGIN} slice={slice}/>;
        }
        case "accordions": {
            return <Accordions className={BASE_MARGIN} slice={slice}/>;
        }
        case "cookie_declaration": {
            return <CookieDeclaration className={BASE_MARGIN} slice={slice}/>;
        }
        case "buttons": {
            return <Buttons slice={slice} className={BASE_MARGIN}/>;
        }
        default: {
            return <></>;
        }
    }
};
