import { gtmAdpageItemList } from "@jog/shared";
import { useCallback, useEffect, useState } from "react";
export const useShopTheLook = (fetch, keys) => {
    const [pending, setPending] = useState(true);
    const [products, setProducts] = useState([]);
    const [sizes, setSizes] = useState({});
    const fetchData = useCallback(async () => {
        setPending(true);
        const { products, sizes } = await fetch();
        setProducts(products);
        setSizes(sizes);
        setPending(false);
    }, [fetch]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    useEffect(() => {
        products && gtmAdpageItemList(products);
    }, [keys, products]);
    return { pending, products, sizes };
};
