import { CarouselBanners } from "@/Slice";
export const SliceRender = ({ slices }) => (<div>
        {[...slices]
        .filter((slice) => !slice.primary.is_hide_module)
        .map((slice, index) => (<Slice key={index} slice={slice}/>))}
    </div>);
export const Slice = ({ slice }) => {
    switch (slice.slice_type) {
        case "carousel_banners": {
            return <CarouselBanners slice={slice}/>;
        }
        default: {
            return <></>;
        }
    }
};
