export const getGuidedSelling = async (code, answers) => {
    const qs = new URLSearchParams();
    qs.append("code", code);
    answers.forEach((answer) => {
        qs.append(answer[0], answer[1]);
    });
    const response = await fetch(`/api/tweakwise-guided-selling?${qs}`, {
        method: "GET",
    });
    return await response.json();
};
