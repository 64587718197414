import { getCategoryPages } from "./getCategoryPages";
import { getAllMarketPages } from "./getMarketPages";
import { getCtpProductPages, getProductPagesWithCategoryInfo } from "./getProductPages";
import { getAllServicePages } from "./getServicePages";
export const fetchPages = async (locale, callback = console.log) => {
    const products = await getCtpProductPages();
    const { categoriesPathsArray, categoryPages } = await getCategoryPages(locale);
    callback(`Fetch category pages done, total ${categoryPages.length}`);
    const productPages = await getProductPagesWithCategoryInfo(locale, products, categoriesPathsArray);
    callback(`Fetch product pages done, total ${productPages.length}`);
    const servicePages = await getAllServicePages(locale);
    callback(`Fetch service pages done, total ${servicePages.length}`);
    const marketPages = await getAllMarketPages(locale);
    callback(`Fetch market pages done, total ${marketPages.length}`);
    return {
        servicePages,
        marketPages,
        productPages,
        categoryPages,
    };
};
export * from "./getServicePages";
export * from "./getProductPages";
export * from "./getCategoryPages";
export * from "./getMarketPages";
