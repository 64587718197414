import { Link } from "@jog/react-components";
import { asLink, asText } from "@prismicio/helpers";
import { memo } from "react";
export const Sidebar = memo(function Sidebar({ nav }) {
    return (<>
            {[...nav.body].map((slice, index) => (<div className="text-gray-base" key={index}>
                    <div className="relative z-0 my-2.75 hidden cursor-pointer text-base uppercase lg:block">
                        {asText(slice.primary.sub_category)}
                    </div>
                    <ul className="mb-10 hidden lg:block">
                        {slice.items.map((item, index) => (<li className="py-1.5 px-0" key={index}>
                                <Link className="text-gray-base" href={asLink(item.subcategory_url)}>
                                    {asText(item.product_type)}
                                </Link>
                            </li>))}
                    </ul>
                </div>))}
        </>);
});
