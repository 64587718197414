import { BannerSlots, Buttons, CarouselProducts, FeaturedProducts, FiveSlotBanner, SingleBanner, TextHeadline, } from "@/Slice";
import { Paragraphs } from "@/Slice/Paragraphs";
export const SliceRender = ({ slices }) => (<div>
        {[...slices]
        .filter((slice) => !slice.primary.is_hide_module)
        .map((slice, index) => (<Slice key={index} slice={slice}/>))}
    </div>);
const BASE_MARGIN = "mt-2 first:mt-0";
export const Slice = ({ slice }) => {
    switch (slice.slice_type) {
        case "single_banner": {
            return <SingleBanner className={BASE_MARGIN} slice={slice}/>;
        }
        case "text_headline": {
            return <TextHeadline className={BASE_MARGIN} slice={slice}/>;
        }
        case "two_slot_banner":
        case "three_slot_banner":
        case "four_slot_banner":
        case "six_slot_banner": {
            return <BannerSlots className={BASE_MARGIN} slice={slice}/>;
        }
        case "five_slot_banner": {
            return <FiveSlotBanner className={BASE_MARGIN} slice={slice}/>;
        }
        case "paragraphs": {
            return <Paragraphs className="mt-10 mb-5 first:mt-8" slice={slice}/>;
        }
        case "featured_products": {
            return <FeaturedProducts className={BASE_MARGIN} slice={slice}/>;
        }
        case "carousel_products": {
            return <CarouselProducts className={BASE_MARGIN} slice={slice}/>;
        }
        case "buttons": {
            return <Buttons slice={slice} className={BASE_MARGIN}/>;
        }
        default: {
            return <></>;
        }
    }
};
