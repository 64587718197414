import { CheckMark } from "@/components/Icon/CheckMark";
import { usePrismicPdp } from "@jog/react-components";
export const CheckMarks = () => {
    const prismicPdp = usePrismicPdp();
    if (!prismicPdp.checkmarkBox.length)
        return <></>;
    return (<div>
            {prismicPdp.checkmarkBox.map((slice, index) => (<div key={index} style={{ backgroundColor: slice.backgroundColor }} className="relative py-8 px-6 text-base">
                    <ul>
                        {slice.items.map((item, index) => (<li key={index} className="flex items-center py-1">
                                <CheckMark width={20} height={20} className="mr-2 fill-current text-green-main"/>
                                {item}
                            </li>))}
                    </ul>
                </div>))}
        </div>);
};
