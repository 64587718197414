import classNames from "classnames";
const textAlign = {
    Center: "text-center",
    Left: "text-left",
    Right: "text-right",
};
const gridOptions = {
    "1": "grid-cols-1",
    "2": "grid-cols-1 md:grid-cols-2",
    "3": "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
};
export const useTextComponent = (columns, text_align, textColor) => {
    const column = gridOptions[columns] || gridOptions["1"];
    const textContainer = classNames("pt-2.5 md:px-5 md:pt-5", textAlign[text_align] || textAlign["Left"], textColor);
    return { column, textContainer };
};
