import { prefetchPageStaticProps } from "@/lib";
import { getRevalidateTime } from "@jog/shared";
export const buildPageProps = async (pageContext, page, featuredProducts = []) => {
    const revalidate = getRevalidateTime();
    const { loader } = await prefetchPageStaticProps(pageContext);
    const t = new Date().toUTCString();
    return {
        props: { loader, t, featuredProducts, page },
        revalidate,
    };
};
