import { Breadcrumbs } from "@/components";
import { Category } from "@/components/Category";
import { Lister } from "@/components/Lister";
import { ShopTheLook } from "@/components/ShopTheLook";
import Layout from "@/layout";
import { PageNotFound, getNotFoundProps } from "@/lib/notFound";
import { fetchCategory, fetchLister } from "@/lib/prismicio/category";
import { getFeaturedProducts } from "@/lib/prismicio/featuredProducts";
import { fetchShopTheLook } from "@/lib/prismicio/shopTheLook";
import { getPage } from "@/lib/ssg/index";
import { buildPageProps } from "@/lib/ssg/slug";
import { fetchCategory as fetchCtCategory } from "@jog/jeanscentre-base";
import { FeaturedProductsContext, breadcrumbArray, genListerBreadcrumb, useLastLister } from "@jog/react-components";
import { fetchS3CategoryPage, getPaths, lowerCaseEq, lowerCaseLocale, retry } from "@jog/shared";
import { useEffect } from "react";
export const buildCategoryPage = async (redisItem, pageContext) => {
    var _a, _b, _c;
    const { deps, extra, ...others } = redisItem;
    const listerData = await retry(() => fetchCtCategory({ id: deps.ct.id }));
    const title = ((_b = (_a = listerData.descriptionAllLocales) === null || _a === void 0 ? void 0 : _a.find((val) => lowerCaseEq(lowerCaseLocale(), val.locale))) === null || _b === void 0 ? void 0 : _b.value) || "";
    const type = ((_c = deps.prismic) === null || _c === void 0 ? void 0 : _c.type) || "lister";
    switch (type) {
        case "shop_the_look": {
            const prismic = await retry(() => pageContext.preview && pageContext.previewData
                ? fetchShopTheLook(redisItem.deps.prismic.id, pageContext.previewData)
                : fetchS3CategoryPage("shop_the_look", redisItem.deps.prismic.id));
            return buildPageProps(pageContext, {
                ...others,
                categoryInfo: { ...extra, paths: getPaths(listerData.custom.customFieldsRaw), title },
                prismic,
                type: "shopTheLook",
            });
        }
        case "category": {
            if (!redisItem.deps.prismic)
                return getNotFoundProps(pageContext);
            const prismic = await retry(() => pageContext.preview && pageContext.previewData
                ? fetchCategory(redisItem.deps.prismic.id, pageContext.previewData)
                : fetchS3CategoryPage("category", redisItem.deps.prismic.id));
            const featuredProducts = await getFeaturedProducts(prismic, getPage);
            return buildPageProps(pageContext, {
                ...others,
                categoryInfo: { ...extra, paths: getPaths(listerData.custom.customFieldsRaw), title },
                prismic,
                type: "category",
            }, featuredProducts);
        }
        case "lister": {
            const prismic = redisItem.deps.prismic
                ? await retry(() => pageContext.preview && pageContext.previewData
                    ? fetchLister(redisItem.deps.prismic.id, pageContext.previewData)
                    : fetchS3CategoryPage("lister", redisItem.deps.prismic.id))
                : null;
            return buildPageProps(pageContext, {
                ...others,
                categoryInfo: {
                    ...extra,
                    pretty: `/${pageContext.params.slug.join("/")}`,
                    paths: getPaths(listerData.custom.customFieldsRaw),
                    title,
                },
                type: "lister",
                prismic,
            });
        }
        default: {
            return getNotFoundProps(pageContext);
        }
    }
};
export const ShopTheLookPageContent = ({ page }) => {
    const { setPath } = useLastLister();
    const { categoryInfo, prismic } = page;
    useEffect(() => {
        setPath(`/${page.params.slug.join("/")}`);
    }, [page.params.slug, setPath]);
    return (<Layout gtmTypeOfPage="lister">
            <Breadcrumbs list={breadcrumbArray(page.params.slug)}/>
            <ShopTheLook paths={categoryInfo.paths} keys={categoryInfo.keys} prismic={prismic.data}/>
        </Layout>);
};
export const ListerContent = ({ page }) => {
    const { categoryInfo, prismic } = page;
    const { setPath } = useLastLister();
    useEffect(() => {
        setPath(`/${page.params.slug.join("/")}`);
    }, [page.params.slug, setPath]);
    return (<Layout gtmTypeOfPage="lister">
            <Breadcrumbs list={genListerBreadcrumb(page.params.slug)}/>
            <Lister title={categoryInfo.title} keys={categoryInfo.keys} prismic={prismic === null || prismic === void 0 ? void 0 : prismic.data} path={categoryInfo.path} pretty={categoryInfo.pretty} paths={categoryInfo.paths}/>
        </Layout>);
};
export const CategoryPageContent = ({ page, featuredProducts, }) => {
    const { prismic, categoryInfo } = page;
    const { setPath } = useLastLister();
    useEffect(() => {
        setPath(`/${page.params.slug.join("/")}`);
    }, [page.params.slug, setPath]);
    if (!prismic)
        return <PageNotFound />;
    return (<Layout gtmTypeOfPage="lister">
            <FeaturedProductsContext.Provider value={{ products: featuredProducts !== null && featuredProducts !== void 0 ? featuredProducts : [] }}>
                <Breadcrumbs list={breadcrumbArray(page.params.slug)}/>
                <Category title={categoryInfo.title} prismic={prismic.data} paths={categoryInfo.paths}/>
            </FeaturedProductsContext.Provider>
        </Layout>);
};
