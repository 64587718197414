import { Breadcrumbs } from "@/components";
import { Pdp } from "@/components/Pdp";
import Layout from "@/layout";
import { PageNotFound } from "@/lib/notFound";
import brandsJson from "@/lib/prismicio/prismic-brands.json";
import breadcrumbSpecialCategory from "@/lib/prismicio/prismic-breadcrumb.json";
import pdpJson from "@/lib/prismicio/prismic-pdp.json";
import pdpSizesJson from "@/lib/prismicio/prismic-pdpSizes.json";
import { buildPageProps } from "@/lib/ssg/slug";
import { fetchProduct } from "@jog/jeanscentre-base";
import { PdpProvider, usePdpBreadcrumb } from "@jog/react-components";
import { buildProductDetailInfo, prismicClient, retry, transPdp } from "@jog/shared";
export const buildProductPage = async (redisItem, pageContext) => {
    const { deps, extra: { categoryKey, productCategoryPath }, ...others } = redisItem;
    const pdpData = await retry(() => fetchProduct({ id: deps.ct.id, locale1: "en", locale2: process.env.NEXT_PUBLIC_LOCALE }));
    const prismic = await retry(() => pageContext.previewData
        ? prismicClient({ previewData: pageContext.previewData })
            .getSingle("pdp")
            .then((res) => transPdp(res.data))
        : Promise.resolve(pdpJson));
    return buildPageProps(pageContext, {
        ...others,
        productDetailInfo: {
            ...buildProductDetailInfo({
                pdpData,
                prismic,
                categoryKey,
                json: { pdpSizesJson, brandsJson },
            }),
            productCategoryPath,
        },
        prismic: { ...prismic, sustainable: [] },
    });
};
export const ProductPageContent = ({ page }) => {
    const list = usePdpBreadcrumb(page.params.slug, breadcrumbSpecialCategory);
    if (!page.prismic || !page.productDetailInfo || !page.productDetailInfo.published)
        return <PageNotFound />;
    return (<Layout gtmTypeOfPage="pdp">
            <PdpProvider key={page.path} detail={page.productDetailInfo} pdp={page.prismic}>
                <Breadcrumbs list={list}/>
                <Pdp />
            </PdpProvider>
        </Layout>);
};
