import { getSSGPathData, graphqlClient, log, productQuery, productsQuery, retry, totalProductsQuery, typedBoolean } from "@jog/shared";
import { kebabCase } from "lodash-es";
let i = 0;
const fetchProducts = async (variables) => {
    return graphqlClient
        .execute({
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: productsQuery,
            variables
        }
    })
        .then((res) => {
        console.log(`${i++} Fetch product api ${variables.offset} ${variables.limit} finish`);
        return res.body.data.products.results;
    });
};
export const getCtpProductPages = async () => {
    log("product");
    const request = {
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: totalProductsQuery
        }
    };
    const totalNumber = await retry(() => graphqlClient.execute(request).then((res) => res.body.data.products.total));
    const perPage = 200;
    const totalPages = Math.ceil(totalNumber / perPage);
    console.info(`Total products: ${totalNumber}`);
    console.info(`Total pages: ${totalPages}`);
    const vars = [];
    for (let page = 0; page < totalPages; page++) {
        vars.push({ offset: page * perPage, limit: perPage });
    }
    const data = await Promise.all(vars.map((i) => retry(() => fetchProducts(i))));
    return data.reduce((pre, cur) => [...pre, ...cur], []);
};
export const getProductPagesWithCategoryInfo = async (locale, products, categoriesPathsArray) => {
    return products
        .map((product) => {
        var _a, _b;
        const categories = product.masterData.current.categories.sort((a, b) => a.orderHint - b.orderHint);
        const productCategoryPathArr = categoriesPathsArray.find((val) => val.category.id === categories[0].id);
        const productName = ((_a = product.masterData.current.nameAllLocales.find((item) => item.locale.toLowerCase() === "en")) === null || _a === void 0 ? void 0 : _a.value) ||
            "";
        const productSlug = ((_b = product.masterData.current.slugAllLocales.find((item) => item.locale.toLowerCase() === "en")) === null || _b === void 0 ? void 0 : _b.value) ||
            "";
        if (productCategoryPathArr && productSlug && productName)
            return { product, productCategoryPathArr, productName, productSlug };
        return;
    })
        .filter(typedBoolean)
        .map(({ product, productCategoryPathArr, productSlug, productName }) => {
        var _a;
        const productCategoryPath = ((_a = productCategoryPathArr === null || productCategoryPathArr === void 0 ? void 0 : productCategoryPathArr.pathParamsArr) === null || _a === void 0 ? void 0 : _a.length)
            ? `/${productCategoryPathArr.pathParamsArr.join("/")}`
            : "product";
        const productPath = `${productCategoryPath}/${kebabCase(productName)}/${kebabCase(productSlug)}`;
        const productImages = product.masterData.current.masterVariant.images;
        const image = (productImages.find(i => i.label === "onmodel-front-500") || productImages.at(0));
        return {
            type: "product",
            ...getSSGPathData(productPath, locale),
            extra: {
                categoryKey: productCategoryPathArr.category.key,
                productCategoryPath,
                image: image === null || image === void 0 ? void 0 : image.url
            },
            deps: {
                ct: {
                    id: product.id,
                    category: productCategoryPathArr.parents.map((i) => i.id)
                }
            },
            lastModifiedAt: product.lastModifiedAt
        };
    })
        .filter(typedBoolean);
};
export const fetchProduct = async (variables) => {
    return graphqlClient
        .execute({
        uri: `/ctp/graphql`,
        method: "POST",
        body: {
            query: productQuery,
            variables
        }
    })
        .then((res) => res.body.data.product);
};
