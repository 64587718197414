import { prefetchLayout } from "@/lib/preFetch/preFetchLayout";
import { prefetchSimplePdp } from "@/lib/preFetch/preFetchPdp";
import { prefetchPromoTag } from "@/lib/preFetch/prefetchPromoTag";
import { initPrefetch } from "@jog/react-components";
import { getRevalidateTime } from "@jog/shared";
export async function prefetchPageStaticProps(pageContext, initRequests = []) {
    const { loader, prefetch } = await initPrefetch(pageContext, [
        prefetchLayout,
        prefetchPromoTag,
        prefetchSimplePdp,
        ...initRequests,
    ]);
    return { loader, prefetch };
}
export async function staticPage(pageContext, more, prefetch = []) {
    const { loader } = await prefetchPageStaticProps(pageContext, prefetch);
    const data = more ? await more(pageContext) : null;
    return {
        props: { loader, data },
        revalidate: getRevalidateTime(),
    };
}
