import { useCallback, useState } from "react";
export const useSwipeable = (length) => {
    const [active, setActive] = useState(0);
    const onSwipedRight = useCallback(() => setActive((index) => (index === 0 ? length - 1 : index - 1)), [length]);
    const onSwipedLeft = useCallback(() => setActive((index) => (index === length - 1 ? 0 : index + 1)), [length]);
    return {
        active,
        setActive,
        onSwipedLeft,
        onSwipedRight,
    };
};
